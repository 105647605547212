var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"monarch-view container-fluid"},[_c('h2',{staticClass:"page-title"},[_vm._v("Text Annotator")]),_c('div',[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-8 is-offset-2"},[_c('div',{staticClass:"stepper-box"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"divider-line"}),_c('div',{staticClass:"steps-wrapper"},[_c('div',{ref:"step1",staticClass:"step"},[_vm._m(0),_vm._m(1)]),_c('div',{ref:"step2",class:['step', _vm.resultsStep ? '' : 'deactivated']},[_vm._m(2),_vm._m(3)])])]),_c('div',{staticClass:"content"},[(!_vm.resultsStep)?_c('div',{staticClass:"step1"},[_c('b-link',{on:{"click":_vm.populateExample}},[_c('i',{staticClass:"fa fa-arrow-circle-down",attrs:{"aria-hidden":"true"}}),_vm._v(" Try example ")]),_c('br'),_c('b-form-textarea',{class:[
                      'textarea',
                      _vm.$v.form.message.$error ? 'is-danger' : '',
                    ],attrs:{"id":"textarea","placeholder":"Enter something...","rows":"12","max-rows":"6"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('br'),_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":"true","unchecked-value":"false"},model:{value:(_vm.longestOnly),callback:function ($$v) {_vm.longestOnly=$$v},expression:"longestOnly"}},[_vm._v(" Always use longest match. ")])],1):_vm._e(),(_vm.resultsStep)?_c('div',{staticClass:"step2"},[_c('div',{ref:"step2text",staticClass:"step2",domProps:{"innerHTML":_vm._s(_vm.annotTextCleaned)}})]):_vm._e(),(_vm.showSpinner)?_c('div',{staticClass:"spinner"},[_c('b-spinner',{attrs:{"type":"grow","label":"Spinning"}})],1):_vm._e(),(_vm.errorAnnotating)?_c('div',{staticClass:"error"},[_vm._m(4)]):_vm._e()]),_c('div',{class:['bottom', !_vm.annotatedText ? 'only-submit' : '']},[(_vm.annotatedText)?_c('b-button',{ref:"backButton",staticClass:"stepper-button back",attrs:{"disabled":!_vm.validForm},on:{"click":_vm.back}},[_c('i',{staticClass:"fa fa-caret-left fa-fw"}),_vm._v("Back ")]):_vm._e(),(!_vm.resultsStep)?_c('b-button',{ref:"submitButton",staticClass:"stepper-button submit",attrs:{"disabled":!_vm.validForm},on:{"click":_vm.annotateText}},[_vm._v(" Submit"),_c('i',{staticClass:"fa fa-caret-right fa-fw"})]):_vm._e(),(_vm.annotatedText)?_c('b-button',{ref:"downloadAnnotations",staticClass:"stepper-button submit",attrs:{"disabled":!_vm.validForm},on:{"click":_vm.exportAnnotations}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.focus",modifiers:{"hover":true,"focus":true}}],staticClass:"fa fa-info-circle",attrs:{"title":_vm.TOOLTIP_DOWNLOAD}}),_vm._v(" Download Annotations ")]):_vm._e(),(_vm.annotatedText)?_c('b-button',{ref:"analyzePhenotypes",staticClass:"stepper-button submit",attrs:{"to":{
                    name: 'analyze-phenotypes',
                    params: { phenotypes: _vm.phenotypes },
                  },"disabled":!_vm.validForm}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.focus",modifiers:{"hover":true,"focus":true}}],staticClass:"fa fa-info-circle",attrs:{"title":_vm.TOOLTIP_ANALYZE}}),_vm._v(" Analyze Phenotypes"),_c('i',{staticClass:"fa fa-caret-right fa-fw"})]):_vm._e()],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-file-text fa-fw"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title"},[_c('h4',[_vm._v("Data")]),_c('h5',{staticClass:"step-subtitle"},[_vm._v(" Enter a phrase, piece of text or abstract ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-check fa-fw"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-title"},[_c('h4',[_vm._v("Results")]),_c('h5',{staticClass:"step-subtitle"},[_vm._v("Your annotated results")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v(" Sorry, looks like we couldn't process that text. If you think this is an error, please open a ticket "),_c('a',{attrs:{"href":"https://github.com/monarch-initiative/helpdesk/issues","target":"__blank"}},[_vm._v(" here")]),_vm._v(" and we can address it right way. ")])
}]

export { render, staticRenderFns }